<template>
  <b-card-code>
    <b-modal id="editCredit" title="Hitel szerkesztés" size="lg" hide-footer >
      <b-row class="my-1 pl-1 pr-1">
        <b-col lg="12">
          <p class="mb-2">Logolt változások</p>
          <table class="table">
            <thead>
              <tr>
                <th>Dátum</th>
                <th>Hitel változás</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(credit_log, index) in employee_data.credit_log" :key="index">
                <td>{{ credit_log.date }}</td>
                <td>
                  {{ credit_log.text }}
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col lg="12">
          <p class="mb-2">Levonások</p>
          <table class="table">
            <thead>
              <tr>
                <th>Dátum</th>
                <th>Hitel összege</th>
                <th>Tőke tartozás</th>
                <th>Kamat</th>
                <th>Kamat %</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(debit, index) in employee_data.monthly_debit" :key="index">
                <td>{{ debit.date.slice(0, 7) }}</td>
                <td>
                  <b-form-input
                    id="edit_total_debit"
                    v-model="employee_data.monthly_debit[index].debit"
                    type="number"
                    class="w-100"
                  />
                </td>
                <td>
                  <span v-if="debit.interest_total">{{ debit.interest_total }} Ft</span>
                  <span v-else>Újra kalkulálás szükséges</span>
                </td>
                <td>
                  <span v-if="debit.interest_price">{{ debit.interest_price }} Ft</span>
                  <span v-else>0 Ft</span>
                </td>
                <td>
                  <span v-if="debit.interest" class="badge badge-warning">{{ debit.interest }} %</span>
                  <span v-else class="badge badge-warning">0 %</span>
                </td>
              </tr>
            </tbody>
          </table>
        </b-col>
        <b-col lg="12" class="mt-3">
          <div class="text-center mb-3">
            <b-button variant="success" @click="$bvModal.hide('editCredit');updateCredit()">
              Újra számolás
            </b-button>
          </div>
        </b-col>
        <b-col lg="12">
          <p class="mb-2">Új hitel rögzítés</p>
          <b-form-group
            label="Új rögzetett hitel összeg"
            label-for="new_credit"
          >
            <b-form-input
              id="new_credit"
              v-model="new_credit"
              type="number"
              class="w-100"
            />
          </b-form-group>
          <b-form-group
            label="Új havi törlesztő részlet"
            label-for="new_debit"
          >
            <b-form-input
              id="new_debit"
              v-model="new_debit"
              type="number"
              class="w-100"
            />
          </b-form-group>
          <b-form-group
            label="Törlesztés kezdete"
            label-for="new_start_date"
          >
            <b-form-input
              id="new_start_date"
              v-model="new_start_date"
              type="month"
              class="w-100"
            />
          </b-form-group>
          <b-form-group
            label="Mikor kapja meg a hitelt"
            label-for="new_start_date_log"
          >
            <b-form-input
              id="new_start_date_log"
              v-model="new_start_date_log"
              type="date" max="9999-12-31"
              class="w-100"
            />
          </b-form-group>
          <p class="text-center mb-3 mt-2">
            <b-button variant="success" @click="newCredit()">
              Új hitel rögzítése
            </b-button>
          </p>
        </b-col>
        <b-col lg="12">
          <p class="mb-2">Kamat módosítás</p>
          <b-form-group
            label="Új kamat százalék"
            label-for="new_interest"
          >
            <b-form-input
              id="new_interest"
              v-model="new_interest"
              type="number"
              class="w-100"
            />
          </b-form-group>
          <b-form-group
            label="Új kamat érvényesség"
            label-for="new_interest_date"
          >
            <b-form-input
              id="new_interest_date"
              v-model="new_interest_date"
              type="month"
              class="w-100"
            />
          </b-form-group>
          <p class="text-center mb-3 mt-2">
            <b-button variant="success" @click="newInterest()">
              Kamat módosítása
            </b-button>
          </p>
        </b-col>
        <b-col class="mt-3">
          <b-button variant="danger" @click="$bvModal.hide('editCredit')">
            Bezár
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="createCredit" title="Hitel rögzítése" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <b-col lg="12">
          <b-form-group
            label="Rögzített hitel összeg"
            label-for="total_credit"
          >
            <b-form-input
              id="total_credit"
              v-model="total_credit"
              type="number"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group
            label="Kamat (%)"
            label-for="interest"
          >
            <b-form-input
              id="interest"
              v-model="interest"
              type="number"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group
            label="Havi törlesztő"
            label-for="total_debit"
          >
            <b-form-input
              id="total_debit"
              v-model="total_debit"
              type="number"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group
            label="Mikor kapta a kölcsönt?"
            label-for="credit_log"
          >
            <b-form-input
              id="credit_log"
              v-model="credit_log"
              type="date" max="9999-12-31"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group
            label="Törlesztés kezdete"
            label-for="start_date"
          >
            <b-form-input
              id="start_date"
              v-model="start_date"
              type="month"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12">
          <b-form-group
            label="Alkalmazott"
            label-for="employee"
          >
            <b-form-select
              id="employee_id"
              v-model="employee_id"
              :options="employees"
              text-field="name"
              value-field="id"
              class="w-100"
            />
          </b-form-group>
        </b-col>
        <b-col lg="12" class="justify-content-end d-flex my-2">
          <b-button size="sm" variant="danger" @click="$bvModal.hide('createCredit')">
            Mégsem
          </b-button>
          <b-button class="ml-1" size="sm" variant="success" @click="createCredit()">
            Rögzítés
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <div class="row justify-space-between mb-2 align-items-center">
      <div class="col-lg-6">
        <h4>Rögzített hitelek</h4>
      </div>
      <div class="col-lg-6 text-right">
        <button class="btn btn-primary mr-2" @click="$router.push('/credit/archive')">Archiváltak</button>
        <button class="btn btn-primary" @click="$bvModal.show('createCredit')">Új rögzítés</button>
      </div>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: Action -->
        <span v-if="props.column.field === 'total_credit'">
          {{ parseInt(props.row.total_credit).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
        </span>
        <span v-else-if="props.column.field === 'total_debit'">
          {{ parseInt(props.row.total_debit).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
        </span>
        <span v-else-if="props.column.field === 'completed'">
          {{ parseInt(props.row.completed).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
        </span>
        <span v-else-if="props.column.field === 'difference'">
          {{ parseInt(props.row.difference).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item @click="$bvModal.show('editCredit');showCredit(props.row.id)">
                <feather-icon
                  icon="EditIcon"
                  class="mr-50"
                />
                <span>Szerkesztés</span>
              </b-dropdown-item>
              <b-dropdown-item @click="archiveComment(props.row.id)">
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span>Archíválás</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteComment(props.row.id)">
                <feather-icon
                  icon="XCircleIcon"
                  class="mr-50"
                />
                <span>Törlés</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Mutasd 1-től
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['25','50','100']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Swal from 'sweetalert2'
import {
  BBadge, BPagination, BFormSelect, BDropdown, BDropdownItem, BFormInput, BFormGroup, BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BButton,
  },
  data() {
    return {
      pageLength: 25,
      dir: false,
      columns: [
        {
          label: 'Alkalmazott neve',
          field: 'employee_name',
          filterOptions: {
            enabled: true,
            placeholder: 'Szűrés mezőre',
          },
        },
        {
          label: 'Kölcsönt kapta',
          field: 'date',
          filterOptions: {
            enabled: true,
            placeholder: 'Szűrés mezőre',
          },
        },
        {
          label: 'Teljes hitel összeg',
          field: 'total_credit',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés mezőre',
          },
        },
        {
          label: 'Eddig visszafizetve',
          field: 'completed',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés mezőre',
          },
        },
        {
          label: 'Hátralévő hitel összeg',
          field: 'difference',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés mezőre',
          },
        },
        {
          label: 'Havi hitel összeg',
          field: 'total_debit',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés mezőre',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      searchTerm: '',
      employees: [],
      employee_id: '',
      total_credit: '',
      total_debit: '',
      start_date: '',
      credit_log: '',
      interest: '',
      new_interest: '',
      new_interest_date: '',
      employee_data: {},
      new_credit: '',
      new_debit: '',
      new_start_date: '',
      new_start_date_log: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  mounted() {
    this.getMonolitCredits()
    this.getEmployees()
  },
  methods: {
    newInterest() {
      axios({
        method: 'put',
        url: `monolit-credit/change-interest/${this.employee_data.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          id: this.employee_data.id,
          interest: this.new_interest,
          interest_date: this.new_interest_date,
        },
      }).then(() => {
        this.getMonolitCredits()
        this.$bvModal.hide('editCredit')
      })
    },
    updateCredit() {
      axios({
        method: 'put',
        url: `monolit-credit/update/${this.employee_data.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          id: this.employee_data.id,
          total_credit: this.employee_data.total_credit,
          monthly_debit: this.employee_data.monthly_debit,
        },
      }).then(() => {
        this.getMonolitCredits()
      })
    },
    showCredit(id) {
      axios({
        method: 'get',
        url: `monolit-credit/list/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.employee_data = response.data
      })
    },
    getEmployees() {
      axios({
        method: 'get',
        url: 'employee/list/only_name_and_id',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.employees = response.data
      })
    },
    createCredit() {
      axios({
        method: 'post',
        url: 'monolit-credit/create',
        data: {
          employee_id: this.employee_id,
          total_credit: this.total_credit,
          total_debit: this.total_debit,
          start_date: this.start_date,
          credit_log: this.credit_log,
          interest: this.interest,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen törölve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.name = ''
        this.type = ''
        this.$bvModal.hide('createCredit')
        this.getMonolitCredits()
      })
    },
    archiveComment(id) {
      axios({
        method: 'put',
        url: `monolit-credit/archive/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen archiválva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.getMonolitCredits()
      })
    },
    deleteComment(id) {
      Swal.fire({
        title: 'Bizotsan törölni szeretnéd?',
        showDenyButton: false,
        customClass: {
          confirmButton: 'btn btn-primary ml-1',
          cancelButton: 'btn btn-danger mr-1',
        },
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonText: 'Törlés',
        cancelButtonText: 'Mégsem',
      }).then(result => {
        if (result.isDenied) {
          return null
        }
        if (result.isConfirmed) {
          axios({
            method: 'delete',
            url: `monolit-credit/delete/${id}`,
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Sikeresen törölve',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.getMonolitCredits()
          })
        }
      })
    },
    getMonolitCredits() {
      axios({
        method: 'get',
        url: 'monolit-credit/list',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.rows = res.data
      })
    },
    newCredit() {
      axios({
        method: 'post',
        url: `monolit-credit/create_new/${this.employee_data.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        data: {
          new_credit: this.new_credit,
          new_debit: this.new_debit,
          new_start_date: this.new_start_date,
          new_start_date_log: this.new_start_date_log,
        },
      }).then(res => {
        this.employee_data.id = res.data.id
        this.employee_data.total_credit = res.data.total_credit
        this.employee_data.monthly_debit = res.data.monthly_debit
        this.updateCredit()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen frissítve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$bvModal.hide('editCredit')
        this.getMonolitCredits()
        this.new_credit = null
        this.new_debit = null
        this.new_start_date = null
        this.new_start_date_log = null
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
